import React, { useState, useEffect, useCallback, useRef, useMemo, forwardRef, useImperativeHandle } from 'react';
import axios from 'axios';
import io from 'socket.io-client';

// const audioElement = new Audio(process.env.PUBLIC_URL + '/new-message.mp3');
const NEW_MESSAGE_SOUND_PATH = `${process.env.PUBLIC_URL}/new-message.mp3`;

const socket = io('https://barriobucks.com', {
  transports: ['websocket', 'polling'],
  withCredentials: true,
  reconnectionAttempts: 5,
  timeout: 10000,
});

const api = axios.create({
  baseURL: 'https://barriobucks.com/api',
  withCredentials: true,
});

const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return 'Unknown';
  const cleaned = ('' + phoneNumber).replace(/\D/g, '').slice(-10);
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phoneNumber;
};

const normalizePhoneNumber = (phoneNumber) => {
  return phoneNumber ? phoneNumber.replace(/\D/g, '') : '';
};

const Txtr = forwardRef(({
  searchTerm: initialSearchTerm,
  setIsTxtrLoaded,
  selectedThread,
  setSelectedThread,
  messages,
  setMessages,
  threads,
  setThreads
}, ref) => {

  
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);
  const [localSearchTerm, setLocalSearchTerm] = useState(initialSearchTerm);
  const [selectedImage, setSelectedImage] = useState(null);
  const [unreadThreads, setUnreadThreads] = useState(new Set());
  const [isAudioEnabled, setIsAudioEnabled] = useState(false);
  const [showWelcomePopup, setShowWelcomePopup] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observerRef = useRef();
  const [audioContext, setAudioContext] = useState(null);
  const audioBufferRef = useRef(null);  

  const WelcomePopup = ({ onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl">
        <h2 className="text-xl font-bold mb-4">Welcome to Txtr!</h2>
        <p className="mb-4">Your messaging hub is ready.</p>
        <button
          onClick={onClose}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Get Started
        </button>
      </div>
    </div>
  );

  const initializeAudio = useCallback(() => {
    if (audioContext) return; // Already initialized
  
    const context = new (window.AudioContext || window.webkitAudioContext)();
    setAudioContext(context);
  
    fetch('/new-message.mp3')
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => context.decodeAudioData(arrayBuffer))
      .then(audioBuffer => {
        audioBufferRef.current = audioBuffer;
      })
      .catch(error => console.error('Error loading audio:', error));
  }, [audioContext]);

  const playSound = useCallback(() => {
    if (audioContext && audioBufferRef.current && isAudioEnabled) {
      const source = audioContext.createBufferSource();
      source.buffer = audioBufferRef.current;
      source.connect(audioContext.destination);
      source.start(0);
    }
  }, [audioContext, isAudioEnabled]);

  const enableAudio = useCallback(() => {
    initializeAudio();
    setIsAudioEnabled(true);
  }, [initializeAudio]);

  const lastThreadElementRef = useCallback(node => {
    if (isLoading) return;
    if (observerRef.current) observerRef.current.disconnect();
    observerRef.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        fetchThreads();
      }
    });
    if (node) observerRef.current.observe(node);
  }, [isLoading, hasMore]);

  const fetchCustomerInfo = useCallback(async (phone) => {
    try {
      const response = await api.get('/customer-info', { params: { phone } });
      return response.data.customer || null;
    } catch (err) {
      console.error(`Error fetching customer info for ${phone}:`, err);
      return null;
    }
  }, []);

  const UnreadAlert = ({ unreadCount, onClearAll }) => (
    <div className="bg-blue-200 p-2 flex justify-between items-center">
      <span>{unreadCount} unread</span>
      <button onClick={onClearAll} className="text-blue-600 hover:text-blue-800">
        Clear all
      </button>
    </div>
  );

  const clearAllUnread = useCallback(() => {
    setUnreadThreads(new Set());
  }, []);

  const fetchThreads = useCallback(async (pageToFetch = page, searchTerm = localSearchTerm) => {
    if (!hasMore) return;
    setIsLoading(true);
    try {
      const response = await api.get('/threads', {
        params: { page: pageToFetch, limit: 20, search: searchTerm }
      });
      const newThreads = await Promise.all(response.data.map(async (thread) => {
        const customerInfo = await fetchCustomerInfo(thread.phone_number);
        return { ...thread, customerName: customerInfo ? customerInfo.name : null };
      }));
      setThreads(prevThreads => {
        const updatedThreads = [...prevThreads, ...newThreads];
        return updatedThreads.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      });
      setHasMore(newThreads.length === 20);
      setPage(pageToFetch + 1);
      setError(null);
    } catch (error) {
      console.error('Error fetching threads:', error);
      setError('Failed to fetch threads. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [hasMore, localSearchTerm, setThreads, page, fetchCustomerInfo]);

  const fetchMessages = useCallback(async (phoneNumber) => {
    try {
      const response = await api.get(`/messages/${phoneNumber}`);
      setMessages(prevMessages => {
        const newMessages = response.data.filter(newMsg =>
          !prevMessages.some(prevMsg =>
            prevMsg.id === newMsg.id ||
            (prevMsg.body === newMsg.body &&
              Math.abs(new Date(prevMsg.received_at) - new Date(newMsg.received_at)) < 5000)
          )
        );
        return [...prevMessages, ...newMessages];
      });
    } catch (error) {
      console.error('Error fetching messages:', error);
      setError('Failed to fetch messages. Please try again.');
    }
  }, [setMessages]);

  const refreshMessages = useCallback(() => {
    if (selectedThread) {
      fetchMessages(selectedThread.phone_number);
    }
  }, [selectedThread, fetchMessages]);

  const updateThread = useCallback((phoneNumber, lastMessage, timestamp) => {
    if (phoneNumber === process.env.REACT_APP_TWILIO_PHONE_NUMBER) {
      return; // Don't update if it's the Twilio number
    }
    setThreads(prevThreads => {
      const threadIndex = prevThreads.findIndex(thread => thread.phone_number === phoneNumber);
      let updatedThreads;
      if (threadIndex === -1) {
        // If the thread doesn't exist, create a new one
        const newThread = {
          phone_number: phoneNumber,
          last_message: lastMessage,
          timestamp: timestamp || new Date().toISOString(),
          customerName: null // You might want to fetch customer info here if needed
        };
        updatedThreads = [newThread, ...prevThreads];
      } else {
        // If the thread exists, update it
        updatedThreads = [...prevThreads];
        updatedThreads[threadIndex] = {
          ...updatedThreads[threadIndex],
          last_message: lastMessage,
          timestamp: timestamp || new Date().toISOString()
        };
      }
      // Sort threads by timestamp
      return updatedThreads.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    });
  }, [setThreads]);

  const handleOutboundMessage = useCallback(async (message) => {
    console.log('Outbound message received:', message);
    
    // Play audio alert
    if (isAudioEnabled) {
      playSound(); // Remove .catch() as playSound doesn't return a promise
    }
    
    // Fetch customer info if name is not provided
    let customerName = message.customerName;
    if (!customerName) {
      const customerInfo = await fetchCustomerInfo(message.to_number);
      customerName = customerInfo ? customerInfo.name : null;
    }
    
    setThreads(prevThreads => {
      const updatedThreads = prevThreads.map(thread => {
        if (thread.phone_number === message.to_number) {
          return {
            ...thread,
            customerName: customerName || thread.customerName,
            last_message: message.body,
            timestamp: message.received_at
          };
        }
        return thread;
      });
      return updatedThreads.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    });
  
    // Remove this line as we're already playing the sound at the beginning
    // playSound();
    
    if (selectedThread && selectedThread.phone_number === message.to_number) {
      setMessages(prevMessages => {
        const newMessages = [...prevMessages, message];
        return newMessages.sort((a, b) => new Date(a.received_at) - new Date(b.received_at));
      });
    }
    
    // This will ensure the thread is updated or created if it doesn't exist
    updateThread(message.to_number, message.body, message.received_at, customerName);
  }, [selectedThread, setMessages, setThreads, updateThread, fetchCustomerInfo, isAudioEnabled, playSound]);
  
  const handleNewMessage = useCallback((message) => {
    console.log('New message received:', message);
    const isFromCurrentUser = normalizePhoneNumber(message.from_number) === normalizePhoneNumber(process.env.REACT_APP_TWILIO_PHONE_NUMBER);
    const threadPhoneNumber = isFromCurrentUser ? message.to_number : message.from_number;
  
    const isForSelectedThread = selectedThread &&
      normalizePhoneNumber(threadPhoneNumber) === normalizePhoneNumber(selectedThread.phone_number);
  
    if (!isFromCurrentUser) {
      playSound();
      setUnreadThreads(prev => new Set(prev).add(threadPhoneNumber));
    }
  
    if (isForSelectedThread) {
      setMessages(prevMessages => {
        const messageExists = prevMessages.some(msg =>
          msg.id === message.id ||
          (msg.body === message.body &&
            Math.abs(new Date(msg.received_at) - new Date(message.received_at)) < 5000)
        );
  
        if (messageExists) {
          console.log('Message already exists in state, not adding');
          return prevMessages;
        }
  
        console.log('Adding new message to state');
        return [...prevMessages, message];
      });
  
      // Scroll to the bottom of the messages
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  
    updateThread(threadPhoneNumber, message.body, message.received_at);
  }, [selectedThread, setMessages, updateThread]);
  

  const handleThreadSelect = useCallback((thread) => {
    setSelectedThread(thread);
    setError(null);
    setMessages([]);
    fetchMessages(thread.phone_number);
    setUnreadThreads(prev => {
      const newSet = new Set(prev);
      newSet.delete(thread.phone_number);
      console.log('Thread selected:', thread.phone_number);
      console.log('Unread threads after selection:', Array.from(newSet));
      return newSet;
    });
  }, [fetchMessages, setMessages, setSelectedThread]);

  const addNewThread = useCallback((newThread) => {
    setThreads(prevThreads => [newThread, ...prevThreads]);
    setSelectedThread(newThread);
    setMessages([]);
  }, [setThreads, setSelectedThread, setMessages]);


  


  const filteredThreads = useMemo(() => {
    if (!threads) return [];
    const searchTermLower = localSearchTerm.toLowerCase().trim();
    const containsLetters = /[a-zA-Z]/.test(searchTermLower);
    const phoneNumberMatch = searchTermLower.replace(/\D/g, '');

    return threads.filter((thread) => {
      if (!thread || !thread.phone_number) return false;

      if (containsLetters) {
        // Relaxed name search
        if (thread.customerName) {
          const customerNameLower = thread.customerName.toLowerCase();
          const searchTerms = searchTermLower.split(/\s+/);
          return searchTerms.every(term => customerNameLower.includes(term));
        }
        return false;
      } else {
        // Phone number search
        const formattedPhone = formatPhoneNumber(thread.phone_number);
        return formattedPhone.includes(phoneNumberMatch) ||
          thread.phone_number.replace(/\D/g, '').includes(phoneNumberMatch);
      }
    });
  }, [threads, localSearchTerm]);

  useImperativeHandle(ref, () => ({
    updateSearchTerm: (term) => {
      setLocalSearchTerm(term);
    },
    addNewThread,
    refreshMessages,
    addNewMessage: (message) => {
      setMessages(prevMessages => {
        // Check if the message already exists in the state
        const messageExists = prevMessages.some(msg =>
          msg.id === message.id ||
          (msg.body === message.body &&
            Math.abs(new Date(msg.received_at) - new Date(message.received_at)) < 5000)
        );

        if (messageExists) {
          console.log('Message already exists in state, not adding');
          return prevMessages;
        }

        console.log('Adding new message to state');
        return [...prevMessages, message];
      });
    },
    updateThread
  }));

  // useEffect hooks - all grouped together
  useEffect(() => {
    if (showWelcomePopup) {
      const timer = setTimeout(() => {
        initializeAudio();
        enableAudio();
        setShowWelcomePopup(false);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [showWelcomePopup, enableAudio, initializeAudio]);

  useEffect(() => {
    fetchThreads();
    setIsTxtrLoaded(true);
    socket.on('newMessage', handleNewMessage);

    return () => {
      setIsTxtrLoaded(false);
      socket.off('newMessage', handleNewMessage);
    };
  }, [fetchThreads, setIsTxtrLoaded, handleNewMessage]);

  useEffect(() => {
    socket.on('outboundMessage', handleOutboundMessage);

    return () => {
      socket.off('outboundMessage', handleOutboundMessage);
    };
  }, [handleOutboundMessage]);

  useEffect(() => {
    if (selectedThread) {
      fetchMessages(selectedThread.phone_number);
    }
  }, [selectedThread, fetchMessages]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [messages]);

  useEffect(() => {
    const interval = setInterval(() => {
      unreadThreads.forEach(threadPhone => {
        if (!selectedThread || normalizePhoneNumber(selectedThread.phone_number) !== normalizePhoneNumber(threadPhone)) {
          playSound();
        }
      });
    }, 60000);

    return () => clearInterval(interval);
  }, [unreadThreads, selectedThread]);

  const getAttachmentUrl = (filename) => {
    return `/uploads/${filename}`;
  };

  const renderAttachment = (attachment) => {
    if (!attachment || !attachment.filename) {
      console.error('Invalid attachment:', attachment);
      return null;
    }

    const attachmentUrl = getAttachmentUrl(attachment.filename);

    if (attachment.contentType.startsWith('image/')) {
      return (
        <>
          <img
            src={attachmentUrl}
            alt={attachment.filename}
            className="max-w-full h-auto cursor-pointer"
            onClick={() => setSelectedImage(attachmentUrl)}
            onError={(e) => {
              console.error('Image load error:', e);
              e.target.onerror = null;
              e.target.src = '/path/to/fallback/image.png';
            }}
          />
          {selectedImage && (
            <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={() => setSelectedImage(null)}>
              <img src={selectedImage} alt="Full size" className="max-w-full max-h-full" />
            </div>
          )}
        </>
      );
    } else if (attachment.contentType.startsWith('video/')) {
      return (
        <video src={attachmentUrl} controls className="max-w-full h-auto">
          Your browser does not support the video tag.
        </video>
      );
    } else if (attachment.contentType.startsWith('audio/')) {
      return (
        <audio src={attachmentUrl} controls className="w-full">
          Your browser does not support the audio tag.
        </audio>
      );
    } else {
      return (
        <a href={attachmentUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline block">
          Download {attachment.filename}
        </a>
      );
    }
  };

  if (showWelcomePopup) {
    return <WelcomePopup onClose={() => setShowWelcomePopup(false)} />;
  }

  return (
    <div className="flex h-full bg-white pt-4 pb-20">
    <button onClick={initializeAudio} className="hidden">Initialize Audio</button>
      <div className="w-1/3 border-r border-gray-300 h-full overflow-y-auto">
        {unreadThreads.size > 0 && (
          <UnreadAlert
            unreadCount={unreadThreads.size}
            onClearAll={clearAllUnread}
          />
        )}
        {filteredThreads.map((thread, index) => (
          <div
            key={thread.phone_number}
            ref={index === filteredThreads.length - 1 ? lastThreadElementRef : null}
            className={`p-4 border-b border-gray-200 cursor-pointer ${
              selectedThread?.phone_number === thread.phone_number
                ? 'bg-blue-100'
                : 'hover:bg-gray-100'
            }`}
            onClick={() => handleThreadSelect(thread)}
          >
            <div className="flex items-center">
              {unreadThreads.has(thread.phone_number) && (
                <div className="w-2 h-2 bg-blue-500 rounded-full mr-2 flex-shrink-0"></div>
              )}
              <div className="font-semibold text-gray-700 truncate flex-grow">
                {thread.customerName || formatPhoneNumber(thread.phone_number)}
              </div>
            </div>
            {thread.customerName && (
              <div className="text-sm text-gray-600">{formatPhoneNumber(thread.phone_number)}</div>
            )}
            <div className="text-sm text-gray-600 truncate">{thread.last_message}</div>
            <div className="text-xs text-gray-400">{new Date(thread.timestamp).toLocaleString()}</div>
          </div>
        ))}
        {isLoading && <div className="p-4 text-gray-500">Loading more threads...</div>}
      </div>

      <div className="w-2/3 flex flex-col h-full">
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline">{error}</span>
          </div>
        )}

        {selectedThread ? (
          <div className="flex-1 overflow-y-auto">
            <div className="p-4">
              {messages.map((message) => {
                console.log('Rendering message:', message);
                return (
                  <div
                    key={`${message.id}-${message.received_at}`}
                    className={`mb-4 flex ${
                      normalizePhoneNumber(message.from_number) === normalizePhoneNumber(process.env.REACT_APP_TWILIO_PHONE_NUMBER)
                        ? 'justify-end'
                        : 'justify-start'
                    }`}
                  >
                    <div
                      className={`p-2 rounded-lg max-w-xs ${
                        normalizePhoneNumber(message.from_number) === normalizePhoneNumber(process.env.REACT_APP_TWILIO_PHONE_NUMBER)
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-300 text-black'
                      }`}
                    >
                      {message.body}
                      {message.attachments && message.attachments.length > 0 && (
                        <div>
                          <p>Attachments ({message.attachments.length}):</p>
                          {message.attachments.map((attachment, index) => (
                            <div key={index} className="mt-2">
                              {renderAttachment(attachment)}
                            </div>
                          ))}
                        </div>
                      )}
                      <div
                        className={`text-xs mt-1 ${
                          normalizePhoneNumber(message.from_number) === normalizePhoneNumber(process.env.REACT_APP_TWILIO_PHONE_NUMBER)
                            ? 'text-gray-200'
                            : 'text-gray-500'
                        }`}
                      >
                        {new Date(message.received_at).toLocaleString()}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div ref={messagesEndRef} />
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center flex-1 text-gray-500">
            Select a thread to start chatting
          </div>
        )}
      </div>
    </div>
  );

});

export default Txtr;
