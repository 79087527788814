import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Review = () => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [reviewText, setReviewText] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [pictures, setPictures] = useState([]);
  const [contactPreference, setContactPreference] = useState('');
  const [submissionStep, setSubmissionStep] = useState(1);
  const [error, setError] = useState('');
  const [customerExists, setCustomerExists] = useState(false);

  const handlePictureUpload = (e) => {
    setPictures([...pictures, ...e.target.files]);
  };

  const isPhoneOrEmailProvided = () => {
    return phone.trim() !== '' || email.trim() !== '';
  };

  const checkCustomerExists = async (identifier, type) => {
    try {
      const response = await axios.get(`/api/customer-by-${type}`, { params: { [type]: identifier } });
      return response.data.customer;
    } catch (error) {
      console.error(`Error checking customer by ${type}:`, error);
      return null;
    }
  };

  const handlePhoneChange = async (e) => {
    const phoneNumber = e.target.value.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    setPhone(phoneNumber);
    if (phoneNumber.length === 12) {
      const customer = await checkCustomerExists(phoneNumber, 'phone');
      if (customer) {
        setCustomerExists(true);
        setName(customer.name);
        setEmail(customer.email);
      } else {
        setCustomerExists(false);
      }
    }
  };

  const handleEmailChange = async (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    if (emailValue.includes('@') && emailValue.includes('.')) {
      const customer = await checkCustomerExists(emailValue, 'email');
      if (customer) {
        setCustomerExists(true);
        setName(customer.name);
        setPhone(customer.phone);
      } else {
        setCustomerExists(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (rating === 0) {
      setError('Please provide a rating.');
      return;
    }

    if (rating >= 4 && (reviewText.trim() === '' || name.trim() === '')) {
      setError('Please provide your name and review.');
      return;
    }

    if (rating <= 3 && contactPreference === '') {
      setError('Please select a contact preference.');
      return;
    }

    const formData = new FormData();
    formData.append('rating', rating);
    formData.append('review_text', reviewText);
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('contact_preference', contactPreference);
    pictures.forEach((picture) => formData.append('pictures', picture));

    try {
      const response = await axios.post('/api/reviews', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (rating >= 4) {
        setSubmissionStep(2);
      } else {
        setSubmissionStep(4);
      }
    } catch (err) {
      console.error('Error submitting review:', err);
      setError('Failed to submit review. Please try again later.');
    }
  };

  const renderStars = () => {
    return (
      <div className="flex space-x-1">
        {[...Array(5)].map((star, index) => {
          index += 1;
          return (
            <button
              type="button"
              key={index}
              className={`text-3xl ${
                index <= (hover || rating) ? 'text-yellow-400' : 'text-gray-300'
              }`}
              onClick={() => setRating(index)}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(rating)}
            >
              &#9733;
            </button>
          );
        })}
      </div>
    );
  };

  return (
    <div className="max-w-xl mx-auto p-6 bg-white rounded-lg shadow-md mt-10">
      {submissionStep === 1 && (
        <form onSubmit={handleSubmit}>
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Leave a Review</h2>
          {renderStars()}
          <p className="text-gray-600 mt-2">Rate your experience:</p>

          {rating > 0 && (
            <>
              <textarea
                className="w-full mt-4 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800"
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
                placeholder="Share your thoughts..."
                required={rating >= 4}
              ></textarea>

              <input
                type="text"
                className="w-full mt-4 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Your Name"
                required
              />

              <input
                type="tel"
                className="w-full mt-4 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800"
                value={phone}
                onChange={handlePhoneChange}
                placeholder="Phone (optional) ###-###-####"
              />

              <input
                type="email"
                className="w-full mt-4 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800"
                value={email}
                onChange={handleEmailChange}
                placeholder="Email (optional)"
              />

              <div className="mt-4">
                <p className="text-gray-700 mb-2">Preferred contact method:</p>
                <label className="inline-flex items-center mr-4">
                  <input 
                    type="radio" 
                    name="contactPreference"
                    value="call"
                    checked={contactPreference === 'call'} 
                    onChange={(e) => setContactPreference(e.target.value)} 
                  />
                  <span className="ml-2 text-gray-700">Call me</span>
                </label>
                <label className="inline-flex items-center mr-4">
                  <input 
                    type="radio" 
                    name="contactPreference"
                    value="text"
                    checked={contactPreference === 'text'} 
                    onChange={(e) => setContactPreference(e.target.value)} 
                  />
                  <span className="ml-2 text-gray-700">Text me</span>
                </label>
                <label className="inline-flex items-center">
                  <input 
                    type="radio" 
                    name="contactPreference"
                    value="none"
                    checked={contactPreference === 'none'} 
                    onChange={(e) => setContactPreference(e.target.value)} 
                  />
                  <span className="ml-2 text-gray-700">No contact needed</span>
                </label>
              </div>

              <input
                type="file"
                accept="image/*"
                multiple
                className="mt-4"
                onChange={handlePictureUpload}
              />

              <p className="text-sm text-gray-600 mt-4">Leave a review and earn Barrio Bucks for your next order!</p>

              {!isPhoneOrEmailProvided() && (
                <p className="text-red-500 mt-2">Include a phone # or email for thank you points.</p>
              )}

              {error && <p className="text-red-500 mt-2">{error}</p>}

              <button
                type="submit"
                className="w-full mt-6 bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300"
              >
                Submit Review
              </button>
            </>
          )}
        </form>
      )}

      {submissionStep === 2 && (
        <div className="text-center">
          <h2 className="text-2xl font-semibold mb-4 text-green-600">Thank You!</h2>
          <p className="text-gray-700 mb-6">We're glad you had a great experience.</p>
          <p className="text-gray-700 mb-6">Sharing this online helps us a lot!</p>
          <div className="flex justify-center space-x-4">
            <a href="https://www.google.com/search?q=El+Barrio+Burritos"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700 transition duration-300"
            >
              Google (4.1 stars)
            </a>
            <a href="https://www.yelp.com/biz/el-barrio-burritos-brooklyn"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300"
            >
              Yelp (3.7 stars)
            </a>
          </div>
        </div>
      )}

      {submissionStep === 4 && (
        <div className="text-center">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Thank You!</h2>
          <p className="text-gray-700">
            {contactPreference === 'call' && "You've asked us to call you."}
            {contactPreference === 'text' && "You've asked us to text you."}
            {contactPreference === 'none' && "You've asked for no follow up."}
          </p>
          <p className="text-gray-700 mt-2">
            Feel free to call us at any time if that's not right: 718-576-6611.
          </p>
        </div>
      )}
    </div>
  );
};

export default Review;